import moment from "moment";

export const COLUMNS = [
  {
    Header: "id",
    accessor: "id",
    disableFilters: true,
    disableSortBy: true,
    show: false,
  },
  {
    Header: "Title",
    accessor: "docuTitle",
  },
  {
    Header: "Type",
    accessor: "docuKind",
  },
  {
    Header: "Date",
    accessor: "createdAt", // 만료기간 업데이트하기
    Cell: ({value}: {value: number}) => {
      return moment.utc(value).format("YYYY .MM .DD");
    },
  },
  {
    Header: "Expire",
    accessor: "expiresAt", // 만료기간 업데이트하기
    Cell: ({value}: {value: number}) => {
      return moment.utc(value).format("YYYY .MM .DD");
    },
  },
];
