import {IContractor} from "@constants/types/contractor";
import moment from "moment";

export const COLUMNS = [
  {
    Header: "id",
    accessor: "id",
    disableFilters: true,
    disableSortBy: true,
    show: false,
  },
  {
    Header: "requester",
    disableFilters: true,
    disableSortBy: true,
    show: false,
    accessor: ({contractors}: {contractors: IContractor[]}) => {
      return contractors[0];
    },
  },
  {
    Header: "Signed",
    disableFilters: true,
    disableSortBy: true,
    accessor: ({contractors}: {contractors: IContractor[]}) => {
      let uidsArr: string[] = [];
      for (let contractor of contractors) {
        if (contractor.uid) uidsArr.push(contractor.uid);
      }

      let contractorsNum = uidsArr.length;
      let signedContractorsNum = 0;
      for (let uid of uidsArr) {
        if (uid !== "0") signedContractorsNum += 1;
      }

      return `${signedContractorsNum} / ${contractorsNum}`;
    },
  },
  {
    Header: "statusData",
    disableFilters: true,
    disableSortBy: true,
    accessor: ({contractors}: {contractors: IContractor[]}) => {
      let uidsArr = [];
      for (let contractor of contractors) {
        if (contractor.uid) uidsArr.push(contractor.uid);
      }

      let status;
      if (uidsArr.includes("-1")) {
        status = "-1";
      } else if (uidsArr.includes("0")) {
        status = "0";
      } else {
        status = null;
      }

      return status;
    },
  },
  {
    Header: "Title",
    accessor: "docuTitle",
    disableSortBy: true,
    sticky: "top",
  },
  {
    Header: "Type",
    accessor: "docuKind",
  },
  {
    Header: "Date",
    accessor: "createdAt",
    Cell: ({value}: {value: number}) => {
      return moment.utc(value).format("YYYY .MM .DD");
    },
  },
];
