// components
import EditUser from "@components/Profile/EditUser";

const Profile = () => {
  return (
    <>
      <EditUser />
    </>
  );
};

export default Profile;
