// modules
import {useState, useEffect} from "react";

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return [width, height];
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useCheckMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const [width, height] = useWindowDimensions();

  useEffect(() => {
    if (width <= 690) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return isMobile;
}
